import React, {useCallback} from "react";
import {action, observable} from "mobx";
import {observer} from 'mobx-react-lite';
import {Alert} from "react-bootstrap";

class NotificationStore {

    @observable
    isOn: boolean = false;

    @observable
    text: string = '';

    variant: 'primary'|'info'|'success'|'danger' = 'success';

    @action
    onSuccess(text: string) {
        this.isOn = true;
        this.text = text;
        this.variant = 'success';
    }

    @action
    onInfo(text: string) {
        this.isOn = true;
        this.text = text;
        this.variant = 'info';
    }

    @action
    onError(text: string) {
        this.isOn = true;
        this.text = text;
        this.variant = 'danger';
    }

    @action
    onResponseError(error: any) {
        if (error.response && error.response.data && error.response.data.hasOwnProperty('error_message') && error.response.data.error_message) {
            this.text = error.response.data.error_message;
        }
        else {
            this.text = 'Error!';
        }
        this.isOn = true;
        this.variant = 'danger';
    }

    @action
    off() {
        this.isOn = false;
        this.text = '';
    }

    @action
    clear() {
        this.isOn = false;
        this.text = '';
        this.variant = 'success';
    }
}

const context = React.createContext(new NotificationStore());
export const useNotification = () => React.useContext(context);


const Notification: React.FC = observer(() => {

    const notification = useNotification();

    const onClose = useCallback(() => {
        notification.off();
    }, [notification]);

    if (notification.isOn) {
        return (
            <Alert variant={notification.variant} onClose={onClose} dismissible>
                {notification.text}
            </Alert>
        );
    }
    else {
        return null;
    }
});

export default Notification;