import axios from "axios";
import {DefaultRepository} from "./index";

class PermissionRepository extends DefaultRepository {

    create(clientId: string, name: string, description: string) {
        return axios.post(`${this.HOST}/clients/${clientId}/permissions`, {
            name: name,
            description: description
        });
    }

    find(clientId: string) {
        return axios.get(`${this.HOST}/clients/${clientId}/permissions`);
    }

    read(clientId: string, permissionId: string) {
        return axios.get(`${this.HOST}/clients/${clientId}/permissions/${permissionId}`);
    }

    update(clientId: string, permissionId: string, name: string, description: string) {
        return axios.put(`${this.HOST}/clients/${clientId}/permissions/${permissionId}`, {
            name: name,
            description: description
        });
    }

    delete(clientId: string, permissionId: string) {
        return axios.delete(`${this.HOST}/clients/${clientId}/permissions/${permissionId}`);
    }
}

export default new PermissionRepository();