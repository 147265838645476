import {action, observable, runInAction} from "mobx";
import {User} from "../model/User";
import StoreState from "../model/StoreState";
import profileRepository from "../repository/ProfileRepository";

class ProfileStore {
    @observable
    profile?: User;

    @observable
    state: StoreState = 'none';

    @action
    async loadProfile() {
        this.state = 'pending';
        try {
            const {data} = await profileRepository.loadProfile();

            runInAction(() => {
                this.profile = data;
                this.state = 'done'
            });
        } catch (error) {
            runInAction(() => {
                this.profile = undefined;
                this.state = 'error';
            });

            throw error;
        }
    }

    @action
    async update(nickname: string, firstName: string, lastName: string) {
        this.state = 'pending';
        try {
            const {data} = await profileRepository.updateProfile(nickname, firstName, lastName);
            runInAction(() => {
                this.state = 'done';
                this.profile = data;
            })
        } catch (e) {
            runInAction(() => {
                this.state = 'error';
            });
            throw e;
        }
    }

    @action
    clear() {
        this.profile = undefined;
        this.state = 'none';
    }
}

export default ProfileStore;