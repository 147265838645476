import React from "react";
import {action, observable} from "mobx";
import { Button, Modal } from "react-bootstrap";
import {observer} from "mobx-react-lite";

class DeleteDialogStore {

    private defaultText: string = '정말 삭제 하시겠습니까?';
    private onDeleteDefault: VoidFunction = (() => {
    });

    @observable
    isOpen: boolean = false;

    @observable
    text: string = this.defaultText;

    @observable
    onDelete: VoidFunction = this.onDeleteDefault;

    @action
    open(onDelete: VoidFunction, text?: string) {
        this.isOpen = true;
        this.text = text || this.defaultText;
        this.onDelete = onDelete;
    }

    @action
    close() {
        this.isOpen = false;
        this.text = this.defaultText;
        this.onDelete = this.onDeleteDefault;
    }
}

const context = React.createContext(new DeleteDialogStore());
export const useDeleteDialog = () => React.useContext(context);

export const DeleteDialog: React.FC = observer(() => {

    const deleteDialog = useDeleteDialog();

    const handleClose = () => {
        deleteDialog.close();
    };

    const onClickDeleteBtn = () => {
        deleteDialog.onDelete();
        deleteDialog.close();
    };

    return (
        <Modal show={deleteDialog.isOpen} size="sm" onHide={handleClose}>
            <Modal.Body className="pt-4">
                <div className="text-center">
                    <i className="mdi mdi-trash-can-outline h1"/>
                    <h4 className="mt-2">DELETE</h4>
                    <p className="mt-3">{deleteDialog.text}</p>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant={'danger'} onClick={onClickDeleteBtn}>Delete</Button>
                <Button variant={'secondary'} onClick={handleClose}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    );
});

export default DeleteDialog;