import {action, observable} from "mobx";
import {User} from "../model/User";
import StoreState from "../model/StoreState";
import adminRepository from "../repository/AdminRepository";


class ClientAdminListStore {
    @observable
    admins: User[] =[];

    @observable
    state: StoreState = 'none';

    @action
    async load() {
        this.state = 'pending';
        try {
            const {data} = await adminRepository.findClientAdmins();
            this.admins = data;
            this.state = 'done';
        } catch (error) {
            this.state = 'error';
            throw error;
        }
    }
}

export default ClientAdminListStore;