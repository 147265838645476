import {DefaultRepository} from "./index";
import axios from "axios";
import {ClientForm} from "../model/Client";


class ClientRepository extends DefaultRepository {

    find() {
        return axios.get(`${this.HOST}/clients`);
    }

    read(clientId: string) {
        return axios.get(`${this.HOST}/clients/${clientId}`);
    }

    create(form: ClientForm) {
        return axios.post(`${this.HOST}/clients`, form);
    }

    update(clientId: string, form: ClientForm) {
        return axios.put(`${this.HOST}/clients/${clientId}`, form);
    }

    updateSecret(clientId: string) {
        return axios.put(`${this.HOST}/clients/${clientId}/secret`);
    }

    updateAPIKey(clientId: string) {
        return axios.put(`${this.HOST}/clients/${clientId}/api-key`);
    }

    updateHMACApiSecret(clientId: string) {
        return axios.put(`${this.HOST}/clients/${clientId}/hmac-api-secret`);
    }

    issueHMAC(clientId: string) {
        return axios.post(`${this.HOST}/clients/${clientId}/hmac`);
    }

    delete(clientId: string) {
        return axios.delete(`${this.HOST}/clients/${clientId}`);
    }

    findUnits(clientId: string) {
        return axios.get(`${this.HOST}/clients/${clientId}/units`);
    }

    findDisplayClients(environment: string) {
        return axios.get(`${this.HOST}/clients/display`, {
            params: {
                environment
            }
        });
    }
}

export default new ClientRepository();