import {action, observable} from "mobx";
import {ManagementUnit} from "../model/Client";
import managementUnitRepository from "../repository/ManagementUnitRepository";
import StoreState from "../model/StoreState";


class ManagementUnitStore {

    @observable
    units: ManagementUnit[] = [];

    @observable
    state: StoreState = 'none';

    @action
    async load(clientId: string) {
        this.state = 'pending';
        try {
            const {data} = await managementUnitRepository.find(clientId);
            this.units = data;
            this.state = 'done';
        } catch (error) {
            this.state = 'error';
            throw error;
        }
    }

    @action
    clear() {
        this.units = [];
        this.state = 'none';
    }
}

export default ManagementUnitStore;