import {DefaultRepository} from "./index";
import axios from "axios";

class AdminRepository extends DefaultRepository {

    findManagers() {
        return axios.get(`${this.HOST}/admin/managers`);
    }

    createManager(userId: string) {
        return axios.post(`${this.HOST}/admin/managers`, {
            user_id: userId
        });
    }

    deleteManager(userId: string) {
        return axios.delete(`${this.HOST}/admin/managers/${userId}`);
    }

    findClientAdmins() {
        return axios.get(`${this.HOST}/admin/client-admins`);
    }

    createClientAdmin(userId: string) {
        return axios.post(`${this.HOST}/admin/client-admins`, {
            user_id: userId
        });
    }

    deleteClientAdmin(userId: string) {
        return axios.delete(`${this.HOST}/admin/client-admins/${userId}`);
    }
}

export default new AdminRepository();