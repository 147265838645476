import {DefaultRepository} from "./index";
import axios from "axios";


class UserRepository extends DefaultRepository {

    async find(params: {query?: string, offset?: number, limit?: number}) {
        return axios.get(`${this.HOST}/users`, {
            params: params
        });
    }

    async create(data: {username: string, password: string, nickname: string}) {
        return axios.post(`${this.HOST}/users`, data);
    }

    async resetPassword(userId: string) {
        return axios.put(`${this.HOST}/users/${userId}/reset-password`);
    }

    async delete(userId: string) {
        return axios.delete(`${this.HOST}/users/${userId}`);
    }
}

export default new UserRepository();