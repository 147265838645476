import {action, observable} from "mobx";
import {User} from "../model/User";
import StoreState from "../model/StoreState";
import userRepository from "../repository/UserRepository";


class UserListStore {

    @observable
    data: User[] = [];

    @observable
    state: StoreState = 'none';

    @observable
    nextState: StoreState = 'none';

    @observable
    hasNext: boolean = false;

    params: {query?: string, offset: number, limit: number} = {offset: 0, limit: 20};

    @action
    async load(params: {query?: string, offset: number, limit: number}) {
        this.state = 'pending';
        this.params = params;
        try {
            const {data} = await userRepository.find(this.params);
            this.data = data;
            this.hasNext = this.data.length === this.params.limit;
            this.state = 'done';
        } catch (e) {
            this.state = 'error';
            throw e;
        }
    }

    @action
    async next() {
        this.nextState = 'pending';
        this.params.offset = this.params.offset + this.params.limit;

        try {
            const {data} = await userRepository.find(this.params);
            this.data = [...this.data, ...data];
            this.hasNext = data.length === this.params.limit;
            this.nextState = 'done';
        } catch (e) {
            this.nextState = 'error';
            throw e;
        }
    }

    @action
    clear() {
        this.data = [];
        this.state = 'none';
        this.nextState = 'none';
        this.hasNext = false;
        this.params = {offset: 0, limit: 20};
    }
}

export default UserListStore;