import {action, observable, runInAction} from "mobx";
import StoreState from "../model/StoreState";
import {DictType} from "../model";
import axios from "axios";
import {API_HOST} from "../core/variables";

class PasswordRulesStore {
    @observable
    data: DictType = {};

    @observable
    state: StoreState = 'none';

    @action
    async load(clientId?: string) {
        this.state = 'pending';
        try {
            const {data} = await axios.get(`${API_HOST}/auth/password-rules`, {
                params: {client_id: clientId}
            });

            runInAction(() => {
                this.data = data;
                this.state = 'done'
            });
        } catch (error) {
            runInAction(() => {
                this.data = {};
                this.state = 'error';
            });

            throw error;
        }
    }

    @action
    clear() {
        this.data = {};
        this.state = 'none';
    }
}

export default PasswordRulesStore;