import {flow, observable} from "mobx";
import StoreState from "../model/StoreState";
import authenticationRepository from "../repository/AuthenticationRepository";


class AccountStore {
    @observable
    state: StoreState = 'none';

    sendVerificationAccountEmail = flow(function *(this: AccountStore, username: string) {
        this.state = 'pending';
        try {
            yield authenticationRepository.sendVerificationAccountEmail(username);
            this.state = 'done';
        } catch (error) {
            this.state = 'error';
            throw error;
        }
    });

    sendResetPasswordEmail = flow(function *(this: AccountStore, username: string) {
        this.state = 'pending';
        try {
            yield authenticationRepository.sendResetPasswordEmail(username);
            this.state = 'done';
        } catch (error) {
            this.state = 'error';
            throw error;
        }
    });

    resetPassword = flow(function *(this: AccountStore, token: string, password: string) {
        this.state = 'pending';
        try {
            yield authenticationRepository.resetPassword(token, password);
            this.state = 'done';
        } catch (error) {
            this.state = 'error';
            throw error;
        }
    });
}


export default AccountStore;