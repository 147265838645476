import {action, observable, runInAction} from "mobx";
import StoreState from "../model/StoreState";
import authenticationRepository from "../repository/AuthenticationRepository";
import {DictType} from "../model";


class SignUpStore {

    @observable
    redirectInfo?: DictType;

    @observable
    state: StoreState = 'none';

    @action
    async signUp(username: string, password: string, nickname: string, givenName: string, familyName: string, params?: DictType) {
        this.state = 'pending';
        try {
            const {data} = await authenticationRepository.signUp(username, password, nickname, givenName, familyName, params);
            runInAction(() => {
                this.state = 'done';
                this.redirectInfo = data;
            });
        } catch (e) {
            runInAction(() => {
                this.state = 'error';
            });
            throw e;
        }
    }
}

export default SignUpStore;