import {action, observable} from "mobx";
import {ManagementUnit} from "../model/Client";
import managementUnitRepository from "../repository/ManagementUnitRepository";
import StoreState from "../model/StoreState";

class UserManagementUnitStore {
    @observable
    managementUnits: ManagementUnit[] = [];

    @observable
    state: StoreState = 'none';

    async findManagementUnits(clientId: string) {
        this.state = 'pending';
        try {
            const {data} = await managementUnitRepository.findOwn(clientId);
            this.managementUnits = data;
            this.state = 'done';
        } catch (error) {
            this.state = 'error';
            throw  error;
        }
    };

    @action
    clear() {
        this.managementUnits = [];
        this.state = 'none';
    }
}

export default UserManagementUnitStore;