import React, {useEffect, useMemo} from "react";
import {Redirect, Route, RouteProps, useLocation} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {useStores} from "../hooks/useStore";
import {useErrorBoundary} from "../hooks/useErrorBoundary";

const PrivateRoute: React.FC<PrivateRouteProps> = observer(({children, admin, ...routeProps}) => {
    const {authenticationStore} = useStores();
    const location = useLocation();
    const errorBoundary = useErrorBoundary();

    const passLoginPaths = useMemo(() => {
        return ['/login', '/signup'];
    }, []);

    useEffect(() => {
        if (admin && !authenticationStore.isAdmin) {
            errorBoundary.setStatus(403);
        }
    }, [admin, authenticationStore.isAdmin, errorBoundary]);

    if (authenticationStore.state === 'done') {
        return (
            <Route {...routeProps}>
                {children}
            </Route>
        );
    }
    else if (authenticationStore.state === 'error') {
        if (!authenticationStore.isLogin && !passLoginPaths.includes(location.pathname)) {
            return <Redirect to={'/login'} />
        }
        else {
            // 로딩페이지 리턴하면 되나?
            return null;
        }
    }
    else {
        return null;
    }

});

interface PrivateRouteProps extends RouteProps{
    admin: boolean;
}

export default PrivateRoute;