import {action, observable} from "mobx";
import {User} from "../model/User";
import StoreState from "../model/StoreState";
import userRepository from "../repository/UserRepository";


class UserStore {
    @observable
    users: User[] = [];

    @observable
    state: StoreState = 'none';

    @action
    async findUsers(username: string) {
        this.state = 'pending';
        try {
            const { data } = await userRepository.find({query: username});
            this.users = data;
            this.state = 'done';
        } catch (error) {
            this.state = 'error';
            throw error;
        }
    }
}

export default UserStore;