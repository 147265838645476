import {action, flow, observable} from "mobx";
import StoreState from "../model/StoreState";
import authenticationRepository from "../repository/AuthenticationRepository";

class AuthenticationStore {
    // @observable
    // roles: string[] = [];

    @observable
    isLogin: boolean = false;

    @observable
    isAdmin: boolean = false;

    @observable
    state: StoreState = 'none';

    @action
    async load() {
        this.state = 'pending';
        try {
            const {data} = await authenticationRepository.isAdmin();
            this.isAdmin = data.is_admin;
            this.isLogin = true;
            this.state = 'done';
        } catch (e) {
            this.state = 'error';
            throw e;
        }
    }

    // loadRoles = flow(function *(this: AuthenticationStore) {
    //     this.state = 'pending';
    //     try {
    //         const {data} = yield authenticationRepository.findRoles();
    //         this.roles = data;
    //         this.isLogin = true;
    //         this.isAdmin = this.roles.includes(process.env.REACT_APP_SYSTEM_ADMIN_ROLE || '');
    //         this.state = 'done'
    //     } catch (error) {
    //         this.isLogin = false;
    //         this.isAdmin = false;
    //         this.state = 'error';
    //         throw error;
    //     }
    // });

    logout = flow(function *(this: AuthenticationStore) {
        this.state = 'pending';
        try {
            yield authenticationRepository.logout();
            this.state = 'done';
            // this.roles = [];
            this.isLogin = false;
            this.isAdmin = false;
        } catch (error) {
            this.state = 'error';
            throw error;
        }
    });

    @action
    clear() {
        this.state = 'done';
        // this.roles = [];
        this.isLogin = false;
        this.isAdmin = false;
    }
}

export default AuthenticationStore;