import {DefaultRepository} from "./index";
import axios from 'axios';
import {DictType} from "../model";

axios.defaults.withCredentials = true;

class AuthenticationRepository extends DefaultRepository {
    // findRoles() {
    //     return axios.get(`${this.HOST}/identity/roles`);
    // }
    isAdmin() {
        return axios.get(`${this.HOST}/identity/is-admin`);
    }

    logout() {
        return axios.post(`${this.HOST}/auth/logout`);
    }

    sendVerificationAccountEmail(username: string) {
        return axios.post(`${this.HOST}/auth/send-verification-account-email`, {username});
    }

    sendResetPasswordEmail(username: string) {
        return axios.post(`${this.HOST}/auth/send-reset-password-email`, {username});
    }

    resetPassword(token: string, password: string) {
        return axios.put(`${this.HOST}/auth/reset-password`, {
            token, password
        });
    }

    changePassword(password: string, newPassword: string) {
        return axios.post(`${this.HOST}/auth/change-password`, {
            password, new_password: newPassword
        });
    }

    signUp(username: string, password: string, nickname: string, givenName: string, familyName: string, params?: DictType) {
        return axios.post(`${this.HOST}/auth/signup`, {
            username,
            password,
            nickname,
            given_name: givenName,
            family_name: familyName,
            ...params
        });
    }

    getLoginMagicToken(app: string) {
        return axios.get(`${this.HOST}/auth/apps/${app}/token`);
    }
}


export default new AuthenticationRepository();