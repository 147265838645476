
export const menuContent = [
    {
        icon: 'uil-layer-group',
        label: 'Apps',
        to: `/clients`,
        admin: true
    },
    {
        icon: 'uil-users-alt',
        label: 'Users',
        to: '/users',
        admin: true,
    },
    {
        icon: 'uil-cog',
        label: 'Settings',
        to: `/settings`,
        admin: false
    },
];