import React from "react";
import ClientStore from "./ClientStore";
import AuthenticationStore from "./AuthenticationStore";
import PermissionStore from "./PermissionStore";
import UserStore from "./UserStore";
import ManagementUnitStore from "./ManagementUnitStore";
import SystemManagerStore from "./SystemManagerStore";
import UserManagementUnitStore from "./UserManagementUnitStore";
import AccountStore from "./AccountStore";
import SignUpStore from "./SignUpStore";

import ProfileStore from "./ProfileStore";
import SecurityStore from "./SecurityStore";
import DisplayClientStore from "./DisplayClientStore";
import ClientAdminListStore from "./ClientAdminListStore";
import RoleListStore from "./RoleListStore";
import RoleStore from "./RoleStore";
import RolePermissionListStore from "./RolePermissionListStore";
import UserListStore from "./UserListStore";
import PasswordRulesStore from "./PasswordRulesStore";

const storeContext = React.createContext({
    authenticationStore: new AuthenticationStore(),
    accountStore: new AccountStore(),
    clientStore: new ClientStore(),
    managementUnitStore: new ManagementUnitStore(),
    permissionStore: new PermissionStore(),
    userStore: new UserStore(),
    systemManagerStore: new SystemManagerStore(),
    userManagementUnitStore: new UserManagementUnitStore(),
    signUpStore: new SignUpStore(),
    profileStore: new ProfileStore(),
    securityStore: new SecurityStore(),
    displayClientStore: new DisplayClientStore(),
    clientAdminListStore: new ClientAdminListStore(),
    roleListStore: new RoleListStore(),
    roleStore: new RoleStore(),
    rolePermissionListStore: new RolePermissionListStore(),
    userListStore: new UserListStore(),
    passwordRulesStore: new PasswordRulesStore()
});

export default storeContext;