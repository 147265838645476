import {DefaultRepository} from "./index";
import axios from "axios";

class ManagementUnitRepository extends DefaultRepository {

    create(clientId: string, unitType: string, unitId: string, unitName: string, userId: string) {
        return axios.post(`${this.HOST}/management-units`, {
            client_id: clientId,
            unit_type: unitType,
            unit_id: unitId,
            unit_name: unitName,
            user_id: userId
        });
    }

    find(clientId: string) {
        return axios.get(`${this.HOST}/management-units`, {
            params: {
                client_id: clientId
            }
        });
    }

    findOwn(clientId: string) {
        return axios.get(`${this.HOST}/management-units/own`, {
            params: {
                client_id: clientId
            }
        });
    }

    read(managementUnitId: string) {
        return axios.get(`${this.HOST}/management-units/${managementUnitId}`);
    }

    sync(clientId?: string) {
        return axios.post(`${this.HOST}/management-units/sync`, {
            client_id: clientId
        });
    }
}

export default new ManagementUnitRepository();