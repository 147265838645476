import {flow, observable} from "mobx";
import {User} from "../model/User";
import StoreState from "../model/StoreState";
import adminRepository from "../repository/AdminRepository";


class SystemManagerStore {
    @observable
    managers: User[] =[];

    @observable
    state: StoreState = 'none';

    findManagers = flow(function *(this: SystemManagerStore) {
        this.state = 'pending';
        try {
            const {data} = yield adminRepository.findManagers();
            this.managers = data;
            this.state = 'done';
        } catch (error) {
            this.state = 'error';
            throw error;
        }
    });

    createManager = flow(function *(this: SystemManagerStore, userId: string) {
        this.state = 'pending';
        try {
            yield adminRepository.createManager(userId);
            this.state = 'done';
        } catch (error) {
            this.state = 'error';
            throw error;
        }
    });

    deleteManager = flow(function *(this: SystemManagerStore, userId: string) {
        this.state = 'pending';
        try {
            yield adminRepository.deleteManager(userId);
            this.state = 'done';
        } catch (error) {
            this.state = 'error';
            throw error;
        }
    });
}

export default SystemManagerStore;