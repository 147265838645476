import loadable from '@loadable/component';

export const indexView = loadable(() => import("./IndexView"));

export const loginView = loadable(() => import("./authentication/LoginView"));
export const signUpView = loadable(() => import("./authentication/SignUpView"));
export const loginSignedView = loadable(() => import("./authentication/LoginSignedView"));
export const expiredPasswordView = loadable(() => import("./authentication/ExpiredPasswordView"));
export const shouldChangePasswordView = loadable(() => import("./authentication/ShouldChangePasswordView"));
export const personalSettingsView = loadable(() => import("./profile/PersonalSettingsView"));
export const profileView = loadable(() => import("./profile/ProfileView"));
export const securityView = loadable(() => import("./profile/SecurityView"));
export const notAuthenticatedAccountView = loadable(() => import("./authentication/NotAuthenticatedAccountView"));
export const resetPasswordEmailView = loadable(() => import("./authentication/ResetPasswordEmailView"));
export const resetPasswordView = loadable(() => import("./authentication/ResetPasswordView"));
export const invalidTokenView = loadable(() => import("./authentication/InvalidTokenView"));
export const loginRedirectView = loadable(() => import("./authentication/LogInRedirectView"));
export const appLoginView = loadable(() => import("./authentication/AppLoginView"));
export const appLoginRedirectView = loadable(() => import("./authentication/AppLoginRedirectView"));
export const appLogoutView = loadable(() => import("./authentication/AppLogoutView"));

export const clientsView = loadable(() => import("./client/ClientsView"));
export const clientRegistrationView = loadable(() => import("./client/ClientRegistrationView"));
export const clientDetailView = loadable(() => import("./client/ClientDetailView"));
export const clientAdminListView = loadable(() => import("./client/ClientAdminListView"));
// export const roleListView = loadable(() => import("./role/RoleListView"));
export const roleDetailView = loadable(() => import("./role/RoleDetailView"));
export const managementUnitDetailView = loadable(() => import("./client/ManagementUnitDetailView"));

export const userListView = loadable(() => import("./user/UserListView"));
export const settingsView = loadable(() => import("./settings/SettingsView"));

export const notFoundRedirectView = loadable(() => import("./error/NotFoundRedirectView"));
export const notFoundView = loadable(() => import("./error/NotFoundView"));
export const forbiddenView = loadable(() => import("./error/ForbiddenView"));
