import {action, observable} from "mobx";
import {Role} from "../model/Role";
import StoreState from "../model/StoreState";
import roleRepository from "../repository/RoleRepository";


class RoleStore {
    @observable
    data?: Role;

    @observable
    state: StoreState = 'none';


    @action
    async load(clientId: string, roleId: string) {
        this.state = 'pending';
        try {
            const {data} = await roleRepository.read(clientId, roleId);
            this.data = data;
            this.state = 'done';
        } catch (e) {
            this.state = 'error';
            throw e;
        }
    }

    @action
    clear() {
        this.data = undefined;
        this.state = 'none';
    }
}

export default RoleStore;