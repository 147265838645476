import {action, observable} from "mobx";
import {Role} from "../model/Role";
import StoreState from "../model/StoreState";
import roleRepository from "../repository/RoleRepository";

class ClientRoleStore {
    @observable
    data: Role[] = [];

    @observable
    state: StoreState = 'none';

    @action
    async load(clientId: string) {
        this.state = 'pending';
        try {
            const {data} = await roleRepository.find(clientId);
            this.data = data;
            this.state = 'done';
        }
        catch (e) {
            this.state = 'error';
            throw e;
        }
    }

    @action
    clear() {
        this.data = [];
        this.state = 'none';
    }
}

export default ClientRoleStore;
