import axios from 'axios';
import {API_HOST} from "../core/variables";

export class DefaultRepository {
    HOST = API_HOST;

    constructor(host?: string) {
        this.HOST = host || this.HOST;
        axios.defaults.withCredentials = true;
    }
}
