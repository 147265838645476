import {Client, ClientForm} from "../model/Client";
import {action, flow, observable} from "mobx";
import StoreState from "../model/StoreState";
import clientRepository from "../repository/ClientRepository";

class ClientStore {
    @observable
    clients: Client[] = [];

    @observable
    client?: Client = undefined;

    @observable
    state: StoreState = 'none';

    @action
    async findAllClients() {
        this.state = 'pending';
        try {
            const { data } = await clientRepository.find();
            this.clients = data;
            this.state = 'done';
        } catch (error) {
            this.state = 'error';
            throw error;
        }
    }

    findClient = flow(function *(this: ClientStore, clientId: string) {
        this.state = 'pending';
        try {
            const { data } = yield clientRepository.read(clientId);
            this.client = data;
            this.state = 'done';
        } catch (error) {
            this.state = 'error';
        }
    });

    createClient = flow(function *(this: ClientStore, form: ClientForm) {
        this.state = 'pending';
        try {
            const { data } = yield clientRepository.create(form);
            this.client = data;
            this.state = 'done';
        } catch (error) {
            this.state = 'error';
        }
    });

    updateClient = flow(function *(this: ClientStore, clientId: string, form: ClientForm) {
        this.state = 'pending';
        try {
            const { data } = yield clientRepository.update(clientId, form);
            this.client = data;
            this.state = 'done';
        } catch (error) {
            this.state = 'error';
        }
    });

    updateSecret = flow(function *(this: ClientStore, clientId: string) {
        this.state = 'pending';
        try {
            const { data } = yield clientRepository.updateSecret(clientId);
            this.client = data;
            this.state = 'done';
        } catch (error) {
            this.state = 'error';
        }
    });

    updateHMACApiSecret = flow(function *(this: ClientStore, clientId: string) {
        this.state = 'pending';
        try {
            const { data } = yield clientRepository.updateHMACApiSecret(clientId);
            this.client = data;
            this.state = 'done';
        } catch (error) {
            this.state = 'error';
        }
    });

    issueHMAC = flow(function *(this: ClientStore, clientId: string) {
        this.state = 'pending';
        try {
            const { data } = yield clientRepository.issueHMAC(clientId);
            this.client = data;
            this.state = 'done';
        } catch (error) {
            this.state = 'error';
        }
    });

    @action
    async updateAPIKey(clientId: string) {
        this.state = 'pending';
        try {
            const {data} = await clientRepository.updateAPIKey(clientId);
            this.client = data;
            this.state = 'done';
        } catch (e) {
            this.state = 'error';
            throw e;
        }
    }

    deleteClient = flow(function *(this: ClientStore, clientId: string) {
        this.state = 'pending';
        try {
            yield clientRepository.delete(clientId);
            this.client = undefined;
            this.state = 'done';
        } catch (error) {
            this.state = 'error';
        }
    });

    @action
    clear() {
        this.clients = [];
        this.client = undefined;
        this.state = 'none';
    }
}

export default ClientStore;