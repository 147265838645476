import {RoleForm} from "../model/Role";
import axios from "axios";
import {API_HOST} from "../core/variables";

class RoleRepository {

    async create(clientId: string, form: RoleForm) {
        return axios.post(`${API_HOST}/clients/${clientId}/roles`, form);
    }

    async find(clientId: string) {
        return axios.get(`${API_HOST}/clients/${clientId}/roles`);
    }

    async read(clientId: string, roleId: string) {
        return axios.get(`${API_HOST}/clients/${clientId}/roles/${roleId}`);
    }

    async delete(clientId: string, roleId: string) {
        return axios.delete(`${API_HOST}/clients/${clientId}/roles/${roleId}`);
    }

    async update(clientId: string, roleId: string, form: RoleForm) {
        return axios.put(`${API_HOST}/clients/${clientId}/roles/${roleId}`, form);
    }
}


export default new RoleRepository();