import {action, observable} from "mobx";
import {Permission} from "../model/Role";
import StoreState from "../model/StoreState";
import permissionRepository from "../repository/PermissionRepository";


class PermissionStore {
    @observable
    permissions: Permission[] = [];

    @observable
    permission?: Permission;

    @observable
    state: StoreState = 'none';

    async findPermissions(clientId: string) {
        this.state = 'pending';
        try {
            const { data } = await permissionRepository.find(clientId);
            this.permissions = data;
            this.state = 'done';
        } catch (error) {
            this.state = 'error';
            throw error;
        }
    };

    async readPermission(clientId: string, permissionId: string) {
        this.state = 'pending';
        try {
            const { data } = await permissionRepository.read(clientId, permissionId);
            this.permission = data;
            this.state = 'done';
        } catch (error) {
            this.state = 'error';
            throw error;
        }
    };

    async createPermission(clientId: string, name: string, description: string) {
        this.state = 'pending';
        try {
            const { data } = await permissionRepository.create(clientId, name, description);
            this.permission = data;
            this.state = 'done';
        } catch (error) {
            this.state = 'error';
            throw error;
        }
    };

    async deletePermission(clientId: string, permissionId: string) {
        this.state = 'pending';
        try {
            const { data } = await permissionRepository.delete(clientId, permissionId);
            this.permission = data;
            this.state = 'done';
        } catch (error) {
            this.state = 'error';
            throw error;
        }
    };

    @action
    clear() {
        this.permissions = [];
        this.permission = undefined;
        this.state = 'none';
    }
}

export default PermissionStore;