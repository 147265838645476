import axios from "axios";
import {API_HOST} from "../core/variables";

class RolePermissionRepository {

    async create(clientId: string, roleId: string, permissionIds: string[]) {
        return axios.post(`${API_HOST}/clients/${clientId}/roles/${roleId}/permissions`, {
            permission_ids: permissionIds
        });
    }

    async find(clientId: string, roleId: string) {
        return axios.get(`${API_HOST}/clients/${clientId}/roles/${roleId}/permissions`);
    }

    async delete(clientId: string, roleId: string, permissionId: string) {
        return axios.delete(`${API_HOST}/clients/${clientId}/roles/${roleId}/permissions/${permissionId}`);
    }
}

export default new RolePermissionRepository();