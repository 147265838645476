import {DefaultRepository} from "./index";
import axios from "axios";

class ProfileRepository extends DefaultRepository {

    loadProfile() {
        return axios.get(`${this.HOST}/profile`);
    }

    updateProfile(nickname: string, firstName: string, lastName: string) {
        return axios.put(`${this.HOST}/profile`, {
            nickname,
            given_name: firstName,
            family_name: lastName
        })
    }
}

export default new ProfileRepository();