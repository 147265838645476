import {action, observable, runInAction} from "mobx";
import {Client} from "../model/Client";
import clientRepository from "../repository/ClientRepository";
import StoreState from "../model/StoreState";

class DisplayClientStore {
    @observable
    clients: Client[] = [];

    @observable
    state: StoreState = 'none';

    @action
    async findClients(environment: string) {
        this.state = 'pending';

        try {
            const {data} = await clientRepository.findDisplayClients(environment);
            runInAction(() => {
                this.clients = data;
                this.state = 'done';
            });
        } catch (e) {
            runInAction(() => {
                this.state = 'error';
            });
            throw e;
        }
    }
}

export default DisplayClientStore;