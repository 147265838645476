import React, {useEffect, useMemo, useState} from 'react';
import * as Layout from '@42maru/web-starter';
import {Link, Redirect, Route, Switch, useLocation} from 'react-router-dom';
import * as view from './view';
import {useStores} from "./hooks/useStore";
import {observer} from "mobx-react-lite";
import {menuContent} from "./menu";
import "./assets/css/style.scss";
import Notification, {useNotification} from "./hooks/useNotification";
import DeleteDialog from "./hooks/useDeleteDialog";
import Dialog from "./hooks/useDialog";
import PrivateRoute from "./component/PrivateRoute";
import {ErrorBoundary} from "./hooks/useErrorBoundary";
import {AppSwitcher, ClientEnvironment} from "@42maru/keeper-web-tool";
import {API_HOST, KEEPER_HOST} from "./core/variables";
import {getSignedUsers} from "./util/SignedUsersCookie";
import logoImage from "./assets/images/logo.png";


function App() {
    const {authenticationStore, profileStore} = useStores();
    const location = useLocation();
    const notification = useNotification();

    const [menuList, setMenuList] = useState<any>([]);

    const clientEnvironment: ClientEnvironment = useMemo(() => {
        const defaultEnv = process.env.REACT_APP_KEEPER_CLIENT_ENVIRONMENT as ClientEnvironment
        try {
            if (window.document.referrer) {
                const url = new URL(window.document.referrer);
                switch (url.hostname) {
                    case 'localhost':
                        return 'local';
                    case 'cm-dev.42maru.com':
                        return 'dev';
                    case 'cm-stg.42maru.com':
                        return 'stg';
                    case 'cm.42maru.com':
                        return 'prod';
                    case 'maru-nlu-dev.42maru.com':
                        return 'dev';
                    case 'maru-nlu-stg.42maru.com':
                        return 'stg'
                    case 'maru-nlu.42maru.com':
                        return 'prod';
                    default:
                        return defaultEnv;
                }
            }
            else {
                return defaultEnv
            }
        } catch (e) {
            console.error(e);
            return defaultEnv
        }
    }, []);

    const signedUsers = useMemo(() => {
        const signedUsers = getSignedUsers();
        return signedUsers.filter((signedUser: any) => signedUser.username !== profileStore.profile?.username);
    }, [profileStore.profile]);

    useEffect(() => {
        Promise.all([authenticationStore.load(), profileStore.loadProfile()]).then(() => {
            setMenuList(menuContent.filter(menu => !menu.admin || authenticationStore.isAdmin));
        });

        return () => {
            profileStore.clear();
            authenticationStore.clear();
        };
    }, [profileStore, authenticationStore]);

    useEffect(() => {
        notification.clear();
    }, [notification, location.pathname]);

    if (authenticationStore.state === 'pending' || authenticationStore.state === 'none') {
        return null;
    }

    const onClickLogOut = () => {
        window.location.href = `${API_HOST}/auth/logout?logout_uri=${KEEPER_HOST}`;
    };

    // console.info('Render app.')

    return (
        <>
            <Switch>
                <Route exact path={'/login'}>
                    <Layout.NoLayout>
                        <view.loginView/>
                    </Layout.NoLayout>
                </Route>
                <Route exact path={'/login-signed'}>
                    <Layout.NoLayout>
                        <view.loginSignedView/>
                    </Layout.NoLayout>
                </Route>
                <Route exact path={'/signUp'}>
                    <Layout.NoLayout>
                        <view.signUpView/>
                    </Layout.NoLayout>
                </Route>
                <Route exact path={'/apps/:app/login'}>
                    <Layout.NoLayout>
                        <view.appLoginView/>
                    </Layout.NoLayout>
                </Route>
                <Route exact path={'/apps/:app/login-redirect'}>
                    <Layout.NoLayout>
                        <view.appLoginRedirectView/>
                    </Layout.NoLayout>
                </Route>
                <Route exact path={'/apps/:app/logout'}>
                    <Layout.NoLayout>
                        <view.appLogoutView />
                    </Layout.NoLayout>
                </Route>
                <Route path={'/personal'}>
                    <Layout.NoLayout>
                        <view.personalSettingsView/>
                    </Layout.NoLayout>
                </Route>
                <Route exact path={'/not-authenticated-account'}>
                    <Layout.NoLayout>
                        <view.notAuthenticatedAccountView/>
                    </Layout.NoLayout>
                </Route>
                <Route exact path={'/reset-password-email'}>
                    <Layout.NoLayout>
                        <view.resetPasswordEmailView/>
                    </Layout.NoLayout>
                </Route>
                <Route exact path={'/reset-password'}>
                    <Layout.NoLayout>
                        <view.resetPasswordView/>
                    </Layout.NoLayout>
                </Route>
                <Route exact path={'/invalid-token'}>
                    <Layout.NoLayout>
                        <view.invalidTokenView/>
                    </Layout.NoLayout>
                </Route>
                <Route exact path={'/login-redirect'}>
                    <Layout.NoLayout>
                        <view.loginRedirectView/>
                    </Layout.NoLayout>
                </Route>
                <Route exact path={'/expired-password'}>
                    <Layout.NoLayout>
                        <view.expiredPasswordView/>
                    </Layout.NoLayout>
                </Route>
                <Route exact path={'/should-change-password'}>
                    <Layout.NoLayout>
                        <view.shouldChangePasswordView/>
                    </Layout.NoLayout>
                </Route>
                <Route>
                    <ErrorBoundary>
                        <Layout.Wrapper>
                            <Layout.SideMenu>
                                <Link className="logo text-center" to="/">
                                    <span className="logo-lg">
                                        <img src={logoImage} alt="Logo large" height="26" />
                                    </span>
                                </Link>
                                {/*<Layout.Brand homeUrl={"/"} logoImage={logoImage} />*/}
                                <Layout.Menu content={menuList} path={location.pathname} />
                                {profileStore.profile && <AppSwitcher keeperHost={KEEPER_HOST}
                                                                      keeperAPIHost={API_HOST}
                                                                      onClickLogout={onClickLogOut}
                                                                      user={profileStore.profile}
                                                                      signedUsers={signedUsers}
                                                                      environment={clientEnvironment} />}
                            </Layout.SideMenu>
                            <Layout.ContentPage className="content-page-custom">
                                <Layout.Content>
                                    <Layout.ContentContainer>
                                        <Notification />
                                        <Switch>
                                            <Route exact path={'/'}>
                                                {/*<view.indexView />*/}
                                                {authenticationStore.isAdmin ? (
                                                    <Redirect to={'/clients'} />
                                                ): (
                                                    <Redirect to={'/personal'} />
                                                )}

                                            </Route>
                                            <PrivateRoute exact path={'/clients'} admin={true}>
                                                <view.clientsView/>
                                            </PrivateRoute>
                                            <PrivateRoute exact path={'/clients/registration'} admin={true}>
                                                <view.clientRegistrationView/>
                                            </PrivateRoute>
                                            <PrivateRoute path={'/clients/:clientId/roles/:roleId'} admin={true}>
                                                <view.roleDetailView/>
                                            </PrivateRoute>
                                            <PrivateRoute path={'/clients/:clientId/management-units/:managementUnitId'} admin={true}>
                                                <view.managementUnitDetailView/>
                                            </PrivateRoute>
                                            <PrivateRoute path={'/clients/:clientId'} admin={true}>
                                                <view.clientDetailView/>
                                            </PrivateRoute>
                                            <PrivateRoute exact path="/client-admins" admin={true}>
                                                <view.clientAdminListView/>
                                            </PrivateRoute>
                                            <PrivateRoute exact path="/users" admin={true}>
                                                <view.userListView />
                                            </PrivateRoute>
                                            <PrivateRoute path="/settings" admin={true}>
                                                <view.settingsView />
                                            </PrivateRoute>
                                            <Route path={'*'}>
                                                <view.notFoundRedirectView/>
                                            </Route>
                                        </Switch>
                                    </Layout.ContentContainer>
                                </Layout.Content>
                            </Layout.ContentPage>
                        </Layout.Wrapper>
                    </ErrorBoundary>
                </Route>
            </Switch>
            <DeleteDialog />
            <Dialog />
        </>
    );
}


export default observer(App);
