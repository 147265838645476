import {action, observable, runInAction} from "mobx";
import StoreState from "../model/StoreState";
import authenticationRepository from "../repository/AuthenticationRepository";


class SecurityStore {

    @observable
    state: StoreState = 'none';

    @action
    async changePassword(password: string, newPassword: string) {
        this.state = 'pending';
        try {
            await authenticationRepository.changePassword(password, newPassword);
            runInAction(() => {
                this.state = 'done';
            });
        } catch (e) {
            runInAction(() => {
                this.state = 'error';
            });
            throw e;
        }
    }
}

export default SecurityStore;